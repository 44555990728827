<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-clipboard"></i> Simulate Distribution</h2>

        <div class="row listing">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-inline">
                            <div class="form-group mr-1">
                                <input type="number" v-model="filter.clicks" class="form-control" placeholder="Clicks"/>
                            </div>
                            <div class="form-group mr-1">
                                <input type="number" v-model="filter.days" class="form-control" placeholder="Time days/hours"/>
                            </div>

                            <div class="form-group mr-1">
                                <select v-model="filter.function" class="form-control">
                                    <option value="0" selected>Fast</option>
                                    <option value="1">Slow</option>
                                    <option value="2">Uniform</option>
                                    <option value="3">Normal</option>
                                </select>
                            </div>

                            <button type="button" class="btn searchBtn text-white mr-1" style="background-color: #383838" @click="calculate"><i class="fa fa-play" title="Search"></i> <span>Calculate</span></button>
                        </div>
                    </div>
                </div>
                <hr/>

                <highcharts :options="chartOptions"></highcharts>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
// import swal from 'sweetalert';
import {Chart} from 'highcharts-vue';

export default {
    name: 'Distribution',
    props:['user'],
    components: {
        highcharts: Chart
    },
    data: function () {
        return {
            filter: {
              clicks: 100000,
              days: 30,
              function: 0
            },
            chartOptions: {
                title: {
                    text: 'Clicks Distribution'
                },
                yAxis: {
                    title: {
                        text: 'Clicks'
                    }
                },
                xAxis: {
                    title: {
                        text: 'Time'
                    }
                },
                series: [
                    {
                        name: 'Clicks',
                        data: []
                    }
                ]
            }
        }
    },
    created: function(){
        window.addEventListener("keydown",this.windowListener);
        this.calculate()
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    methods:{
        calculate() {
            //Load the providers
            this.$root.preloader = true;

            axios.get(`${this.$root.serverUrl}/admin/schedule/distribution`, {params:this.filter}).then(function(resp){
                this.$root.preloader = false;
                (resp && resp.data && resp.data.data) ? resp.data.data = resp.data.data.map((series) =>  { return {x: series.x, y: series.clicks}}) : [];
                this.chartOptions.series[0].data = resp.data.data;
                let count = 0;
                for(let i = 0; i < resp.data.data.length; i++) {
                    count += Number(resp.data.data[i].y)
                }
                console.log(count)
            }.bind(this));
        }
    }
}
</script>

<style scoped>
.searchBtn span {
    max-width: 0;
    -webkit-transition: max-width 1s;
    -moz-transition: max-width 1s;
    -o-transition: max-width 1s;
    transition: max-width 1s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
}
button:hover span {
    max-width: 9rem;
}
.highcharts-figure, .highcharts-data-table table {
    min-width: 360px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #EBEBEB;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
</style>
